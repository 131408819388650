import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { theme } from "@nulogy/components";
import CodeBlock from "../components/CodeBlock.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Theme`}</h1>
    <p>{`All the available design options for creating interfaces in Nulogy's style can be accessed in Javascript via the theme.`}</p>
    <CodeBlock mdxType="CodeBlock">
  <div className="language-json">{JSON.stringify(theme, null, "  ")}</div>
    </CodeBlock>
    <h2>{`Usage`}</h2>
    <p>{`The theme can be accessed via our building block component props. E.g a `}<a parentName="p" {...{
        "href": "/components/box"
      }}>{`Box`}</a>{`'s `}<inlineCode parentName="p">{`backgroundColor`}</inlineCode>{` prop will map to the `}<inlineCode parentName="p">{`colours`}</inlineCode>{` object in our theme. Similarly, a `}<a parentName="p" {...{
        "href": "/components/Text"
      }}>{`Text`}</a>{` component's `}<inlineCode parentName="p">{`fontSizes`}</inlineCode>{` property will map to the `}<inlineCode parentName="p">{`fontSizes`}</inlineCode>{` object, etc.`}</p>
    <h2>{`Custom themes`}</h2>
    <p>{`As of version 3.0, themes can be overriden at the NDSProvider level. This allows changing all definitions of a property at once.`}</p>
    <p>{`For example, if you were working in one of our legacy Operational Solution modules and wanted the "medium" font size used in all components to be 14px instead of 16px:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { NDSProvider } from "@nulogy/components"

<NDSProvider theme={fontSizes: {medium: "14px"}}>
    // app
</NDSProvider>
`}</code></pre>
    <Alert fontSize="20px" mt="x4" mdxType="Alert">
  This is a powerful feature, but should be used sparingly. Please let Design
  Ops know when you've overriden the theme for any reason, in case there are
  systemic problems we could be solving in the system directly instead.
    </Alert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      